<template>
	<v-app>
		<v-snackbar
			v-model="alert.active"
			top
			center
			:color="alert.color"
		>
			{{ alert.text }}
		</v-snackbar>

		<router-view @getAlert="getAlert" />
	</v-app>
</template>

<script>
export default {
	name: "App",
	data () {
		return {
			alert: {
				active: false,
				color: 'error',
				text: null
			}
		}
	},
	methods: {
		getAlert (msg, type) {
			this.alert.active = true
			this.alert.color = type
			this.alert.text = msg
		}
	},
}
</script>
