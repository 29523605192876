import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import pinia from './store'
import { useAppState } from './store/AppState'
import router from './plugins/router'

Vue.config.productionTip = false

Vue.prototype.$api = axios.create({
	baseURL: process.env.VUE_APP_APIBASE,
	responseType: 'json',
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
})

const someStorePlugin = {
	install (Vue, options) {
		Vue.prototype.store = useAppState(options.pinia)
	}
}

Vue.use(someStorePlugin, { pinia })

const currentHost = window.location.hostname

const list_host = {
	'ptkbarat.mustaatsaman.id': 'Kecamatan Pontianak Barat',
	'ptkkota.mustaatsaman.id': 'Kecamatan Pontianak Kota',
	'ptkselatan.mustaatsaman.id': 'Kecamatan Pontianak Selatan',
	'ptktenggara.mustaatsaman.id': 'Kecamatan Pontianak Tenggara',
	'ptktimur.mustaatsaman.id': 'Kecamatan Pontianak Timur',
	'ptkutara.mustaatsaman.id': 'Kecamatan Pontianak Utara',
	'ptkselteng.mustaatsaman.id': 'Kecamatan Pontianak Selatan/Tenggara',
}

let predefined_selected_kecamatan = Object.keys(list_host).includes(currentHost) ? list_host[currentHost] : ''

if (process.env.VUE_APP_PRODMODE == 'kota' && process.env.NODE_ENV === 'development') predefined_selected_kecamatan = 'Kecamatan Pontianak Selatan/Tenggara'

new Vue({
	vuetify,
	pinia,
	router,
	data: {
		ukuran: {
			picture_sample: 1000*1000*2,
			picture_population: 1000*1000*2,
		},
		str_ukuran: {
			picture_sample: 'Maksimal 2 MB',
			picture_population: 'Maksimal 2 MB',
		},
		prodMode: process.env.VUE_APP_PRODMODE,
		publicPath: process.env.BASE_URL,
		envWorkingPath: process.env.VUE_APP_HOMEDIR,
		thumbImage: process.env.VUE_APP_THUMBIMAGE + '?t=',
		predefined_selected_kecamatan: predefined_selected_kecamatan,
	},
	render: h => h(App)
}).$mount("#app")
