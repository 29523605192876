import Vue from 'vue'
import VueRouter from 'vue-router'
import pinia from '@/store'
import { useAppState } from '@/store/AppState'

let routes

if (process.env.VUE_APP_PRODMODE == 'clone') routes = [
	{
		path: '/',
		component: () => import('@/views/WebHomeIndex'),
		children: [
			{
				path: '/',
				name: 'homepage',
				component: () => import('@/components/home/HomeLogin'),
			},
			{
				path: '/login',
				name: 'login',
				component: () => import('@/components/home/HomeLogin'),
			},
		]
	},
	{
		path: '/admin',
		component: () => import('@/views/WebAdminIndex'),
		meta: {
			levelGranted: ['D', 'S', 'A', 'U'],
		},
		children: [
			{
				path: '/admin',
				name: 'dashboard_utama',
				component: () => import('@/components/admin/AdminIndexPemilih'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/dashboard/pemilih',
				name: 'dashboard_pemilih',
				component: () => import('@/components/admin/AdminIndexPemilih'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/data/pemilih',
				name: 'data_pemilih',
				component: () => import('@/components/admin/AdminDataPemilih'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/account',
				name: 'account',
				component: () => import('@/components/admin/AdminAccount'),
				meta: {
					levelGranted: ['D', 'S'],
				},
			},
			{
				path: '/admin/auditLog',
				name: 'auditLog',
				component: () => import('@/components/admin/AdminAuditLog'),
				meta: {
					levelGranted: ['D', 'S'],
				},
			},
		]
	},
	{
		path: '*',
		component: () => import( '@/views/WebNotFound'),
	},
]

else if (process.env.VUE_APP_PRODMODE == 'kota') routes = [
	{
		path: '/',
		component: () => import('@/views/WebHomeIndex'),
		children: [
			{
				path: '/',
				name: 'homepage',
				component: () => import('@/components/home/HomeLogin'),
			},
			{
				path: '/login',
				name: 'login',
				component: () => import('@/components/home/HomeLogin'),
			},
		]
	},
	{
		path: '/admin',
		component: () => import('@/views/WebAdminIndex'),
		meta: {
			levelGranted: ['D', 'S', 'A', 'U'],
		},
		children: [
			{
				path: '/admin',
				name: 'dashboard_utama',
				component: () => import('@/components/admin/AdminIndexPerolehanSuara'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/dashboard/perolehan-suara',
				name: 'dashboard_perolehan_suara',
				component: () => import('@/components/admin/AdminIndexPerolehanSuara'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/data/tps',
				name: 'data_tps',
				component: () => import('@/components/admin/AdminDataTps'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/data/partai-caleg',
				name: 'data_partai_caleg',
				component: () => import('@/components/admin/AdminDataPartaiCaleg'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/data/perolehan-suara',
				name: 'data_perolehan_suara',
				component: () => import('@/components/admin/AdminDataPerolehanSuara'),
				meta: {
					levelGranted: ['D', 'S', 'A', 'U'],
				},
			},
			{
				path: '/admin/account',
				name: 'account',
				component: () => import('@/components/admin/AdminAccount'),
				meta: {
					levelGranted: ['D', 'S'],
				},
			},
			{
				path: '/admin/auditLog',
				name: 'auditLog',
				component: () => import('@/components/admin/AdminAuditLog'),
				meta: {
					levelGranted: ['D', 'S'],
				},
			},
		]
	},
	{
		path: '*',
		component: () => import( '@/views/WebNotFound'),
	},
]

else routes = [
	{
		path: '/',
		component: () => import('@/views/WebHomeIndex'),
		children: [
			{
				path: '/',
				name: 'homepage',
				component: () => import('@/components/home/HomeIndex'),
			},
			{
				path: '/login',
				name: 'login',
				component: () => import('@/components/home/HomeLogin'),
			},
		]
	},
	{
		path: '/admin',
		component: () => import('@/views/WebAdminIndex'),
		meta: {
			levelGranted: ['D', 'S', 'A', 'U'],
		},
		children: [
			{
				path: '/admin',
				name: 'dashboard_utama',
				component: () => import('@/components/admin/AdminIndexPerolehanSuara'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/dashboard/perolehan-suara',
				name: 'dashboard_perolehan_suara',
				component: () => import('@/components/admin/AdminIndexPerolehanSuara'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/dashboard/pemilih',
				name: 'dashboard_pemilih',
				component: () => import('@/components/admin/AdminIndexPemilih'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/data/pemilih',
				name: 'data_pemilih',
				component: () => import('@/components/admin/AdminDataPemilih'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/data/tps',
				name: 'data_tps',
				component: () => import('@/components/admin/AdminDataTps'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/data/partai-caleg',
				name: 'data_partai_caleg',
				component: () => import('@/components/admin/AdminDataPartaiCaleg'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/data/perolehan-suara',
				name: 'data_perolehan_suara',
				component: () => import('@/components/admin/AdminDataPerolehanSuara'),
				meta: {
					levelGranted: ['D', 'S', 'A', 'U'],
				},
			},
			{
				path: '/admin/account',
				name: 'account',
				component: () => import('@/components/admin/AdminAccount'),
				meta: {
					levelGranted: ['D', 'S'],
				},
			},
			{
				path: '/admin/auditLog',
				name: 'auditLog',
				component: () => import('@/components/admin/AdminAuditLog'),
				meta: {
					levelGranted: ['D', 'S'],
				},
			},
			{
				path: '/admin/test/1',
				name: 'testPage1',
				component: () => import('@/components/admin/AdminTest1'),
			},
			{
				path: '/admin/test/2',
				name: 'testPage2',
				component: () => import('@/components/admin/AdminTest2'),
			},
		]
	},
	{
		path: '*',
		component: () => import( '@/views/WebNotFound'),
	},
]

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach(async (to, from, next) => {
	let nextOrDefault = (condt, def) => condt ? next() : next(def)
	let lRoutes = to.matched.filter(record => record.meta.levelGranted)

	if (lRoutes.length > 0) {
		const store = useAppState(pinia)
		await store.checkLogin()

		let isGranted = false

		if (store.level_user) isGranted = lRoutes[0].meta.levelGranted.includes(store.level_user)

		nextOrDefault(isGranted, '/login')
	} else {
		next()
	}
})

export default router
