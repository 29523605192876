import CryptoJS from 'crypto-js'

export const deserialize = input => {
	try {
		return JSON.parse(CryptoJS.AES.decrypt(input, "n2Ge3aM)>NT<5uWs").toString(CryptoJS.enc.Utf8))
	} catch (error) {
		return {}
	}
}

export const serialize = input => {
	return CryptoJS.AES.encrypt(JSON.stringify(input), "n2Ge3aM)>NT<5uWs")
}
