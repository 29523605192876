import axios from 'axios'
import { defineStore } from 'pinia'
import { serialize, deserialize } from '@/store/_serializer'

export const useAppState = defineStore('app', {
	state: () => ({
		token: null,
		level_user: null,
		data: {},
	}),
	getters: {
		isLoggedIn: state => state.token !== '' && state.token !== null
	},
	actions: {
		setToken (input) {
			this.token = input
		},
		setLogin (data) {
			this.token = data.token
			this.level_user = data.level_user
			this.data = data._data
		},
		setLogout () {
			this.token = null
			this.level_user = null
			this.data = {}
		},
		async checkLogin () {
			if (this.token) {
				let form = new FormData()
				form.append("at", this.token)

				const api = axios.create({
					baseURL: process.env.VUE_APP_APIBASE,
					responseType: 'json',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				})

				const resp = await api.post("api.auth.check.php", form)

				if (!resp.data.error) {
					let data = resp.data.data
					if (data.g == 'ACCESS_GRANTED') {
						this.level_user = data.level_user
						this.data = data._data
					} else {
						this.setLogout()
					}
				} else {
					this.setLogout()
				}
			} else {
				this.setLogout()
			}
		}
	},
	persist: {
		storage: localStorage,
		serializer: {
			deserialize,
			serialize,
		}
	},
})
